import Card from "../../components/Card";
import {getUtenteFromToken} from "../../utility/common";
import {useFormik} from "formik"
import React, {useContext, useEffect, useRef, useState} from "react";
import ModalMsg from "../../components/ModalMsg";
import axios from "axios";
import {AuthContext} from "../../context/AuthContext";
import {Link, useHistory} from "react-router-dom";
import {LoaderContext} from "../../context/LoaderContext";

function Accesso({hash}) {

    const [show, setShow] = useState(false);
    const [msgModal, setMsgModal] = useState('');

    const history = useHistory()
    const authContext = useContext(AuthContext);
    
    // carico riferimento al contesto in modo da poterlo usare correttamente in useEffect senza avere problemi di dipendenze non dichiarate
    const loader = useRef(useContext(LoaderContext));

    const closeModal = () => setShow(false);

    useEffect(() => {
    }, []);

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        }, onSubmit: async values => {
            loader.current.startChiamata();
            await axios
                .post(process.env.REACT_APP_API_ENDPOINT + "/api/cmms/utente/login",
                    {
                        username: values.email,
                        pwd: values.password
                    })
                .then((response) => {
                    if (response?.data) {
                        if (response?.data?.result === 0) {
                            var token = response.data.data.token;
                            localStorage.setItem('cmms-login', token);
                            localStorage.setItem('cmms-login-inst', response.data.data.token_installazioni);

                            var user = getUtenteFromToken(token);
                            authContext.handleLogin(user, history, hash);
                        } else {
                            setMsgModal(response.data.description)
                            setShow(true)
                        }
                    }
                    loader.current.endChiamata();
                });
        }
    });

    return (
        <div>
            <ModalMsg
                show={show}
                handleClose={closeModal}
                testo={msgModal}/>
            <div className="cmms-accesso cmms-card">
                <Card
                    icon={'fas fa-user'}
                    title={"Portale Installazioni"}>
                    <form className="row g-3 needs-validation" method="post" noValidate=""
                          onSubmit={formik.handleSubmit}>
                        <input type="hidden" name="requestedUrl" id="requestedUrl" value="/cleanapp/index.php"/>

                        <div className="col-12">
                            <label htmlFor="email" className="form-label">E-mail</label>
                            <input
                                id="email"
                                name="email"
                                type="email"
                                onChange={formik.handleChange}
                                value={formik.values.email}
                                className="form-control"
                                required/>
                            <div className="invalid-feedback">
                                L'indirizzo E-Mail deve essere valorizzato.
                            </div>
                        </div>
                        <div className="col-12">
                            <label htmlFor="password" className="form-label">Password</label>
                            <input
                                id="password"
                                name="password"
                                type="password"
                                onChange={formik.handleChange}
                                value={formik.values.password}
                                className="form-control"
                                required/>
                            <div className="invalid-feedback">
                                La Password deve essere valorizzata.
                            </div>
                        </div>

                        <div className="col-12 text-center">
                            <div className="d-grid gap-2">
                                <button type="submit" name="accedi" id="accedi"
                                        className="btn btn-dark">
                                    <i className="fas fa-sign-in-alt"/> Accedi
                                </button>
                            </div>
                        </div>
                        <div className="col-12 text-center pt-2">
                            <div className="d-grid gap-2">
                                <Link to={"/recuperaPwd"}>
                                    Recupera password
                                </Link>
                            </div>
                        </div>
                    </form>
                </Card>
            </div>
        </div>
    );
}

export default Accesso;
